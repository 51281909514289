import { CSpinner } from '@coreui/react';
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { PrivateRoute } from './views/auth/PrivateRoute';
import { Logout } from './views/pages/login/Logout';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import FinishedJobsNotificationQueries from "./views/finished-jobs-notifications/FinishedJobsNotificationQueries";
import FinishedJobsNotificationQuery from "./views/finished-jobs-notifications/FinishedJobsNotificationQuery";
import { history } from './_helpers';

const loading = (
    <CSpinner color="warning" variant="grow" />
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
        <Router history={history}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/logout" name="Logout Page" render={props => <Logout {...props}/>} />
              <PrivateRoute path="/" component={TheLayout}/>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              <Route exact path="/finished-jobs-notification-queries" component={FinishedJobsNotificationQueries} />
              <Route path="/finished-jobs-notification-queries/new" component={FinishedJobsNotificationQuery} />
              <Route path="/finished-jobs-notification-queries/:id" component={FinishedJobsNotificationQuery} />
            </Switch>
          </React.Suspense>
        </Router>
    );
  }
}

export default App;