import React, {useEffect, useState} from "react";
import {useDispatch} from 'react-redux';
import {
    Grid,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import config from 'src/config';
import api from 'src/_helpers/api';
import {Loading} from '../../theme-sources/bootstrap/components/loading/loading';
import {history} from 'src/_helpers';
import {DateFieldFormatter, DateFieldTypeProvider} from "../common/formatters/DateCell";
import {finishedJobsNotificationQueriesActions} from 'src/store/_actions/finishedJobsNotificationQueries.actions';

const FinishedJobsNotificationsGrid = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getNotificationQueriesFromSynapse = () => {
        setLoading(true);
        let URL = `${config.apiUrl}finished-jobs-notification-queries`;
        api().get(URL).then((response) => {
            const notificationsData = response.data;
            if (notificationsData.length > 0) {
                setRows(notificationsData);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            history.push('/');
            history.go(0);
        });
    }

    const handleDelete = (id) => {
        dispatch(finishedJobsNotificationQueriesActions.remove(id)).then(() => {
            setRows(rows.filter(row => row.id !== id));
        });
    }

    const handleEdit = (id) => {
        history.push(`/finished-jobs-notification-queries/${id}`);
    }

    useEffect(() => {
        getNotificationQueriesFromSynapse();
    }, []);

    return (
        <div style={{position: 'relative'}}>

            {/*Loading spinner*/}
            <div className="finished-jobs-loading">
                {loading && <Loading/>}
            </div>

            <Grid
                rows={rows}
                columns={[
                    {name: 'id', title: 'ID', width: 60},
                    {name: 'notification_description', title: 'Notification Description', width: 200},
                    {name: 'severity', title: 'Severity', width: 100},
                    {name: 'user_notes', title: 'User Notes', width: 200},
                    {name: 'created_at', title: 'Created At', width: 200},
                    {name: 'last_modified', title: 'Last Modified', width: 200},
                    {name: 'actions', title: 'Actions', width: 150},
                ]}
            >
                <DateFieldTypeProvider for={['created_at']} formatterComponent={DateFieldFormatter} {...props} />
                <VirtualTable
                    height="auto"
                    noDataText="No data to display"
                    cellComponent={({ column, row, ...restProps }) => {
                        if (column.name === 'actions') {
                            return (
                                <VirtualTable.Cell {...restProps}>
                                    <button onClick={() => handleEdit(row.id)} className="btn btn-primary btn-sm">
                                        EDIT
                                    </button>
                                    <button onClick={() => handleDelete(row.id)} className="btn btn-danger btn-sm ml-2">
                                        DELETE
                                    </button>
                                </VirtualTable.Cell>
                            );
                        }
                        return <VirtualTable.Cell {...restProps} />;
                    }}
                />
            </Grid>

        </div>
    );
}

export default FinishedJobsNotificationsGrid;