import { DataTypeProvider } from '@devexpress/dx-react-grid';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getLsDateZone, toLocalTimeZone, toUTC } from 'src/_helpers';

export const DateFieldFormatter = (props) => {
    const [lsDateZone, gofSettings] = getLsDateZone();
    const timeZone = useSelector(state => state.common.timeZone) || lsDateZone;

    if (timeZone === 'Local') { 
        return (props.row[props.column.name] ? <>{toLocalTimeZone(props.row[props.column.name])}</> : '')
    } else {
        return (props.row[props.column.name] ? <>{toUTC(props.row[props.column.name])}</> : '')
    }
};

export const DateFieldTypeProvider = props => (
<DataTypeProvider
    formatterComponent={DateFieldFormatter}
    {...props}
/>
);



