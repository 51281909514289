import { finishedJobsNotificationQueryConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { failure, request, success } from '../../_helpers';
import { commonActions } from './common.actions';
import api from 'src/_helpers/api';

export const finishedJobsNotificationQueriesActions = {
    all,
    update,
    one,
    remove,
    create
};

function all() {
    return dispatch => {
        dispatch(request(finishedJobsNotificationQueryConstants.GET_REQUEST));
        api().get('api/finished-jobs-notification-queries').then(response => {
            const finishedJobsNotificationQueries = response.data;
            dispatch(success(finishedJobsNotificationQueryConstants.GET_SUCCESS, {all: finishedJobsNotificationQueries}));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(finishedJobsNotificationQueryConstants.GET_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    }
}

function one(id) {
    return dispatch => {
        dispatch(request(finishedJobsNotificationQueryConstants.GET_REQUEST));
        return api().get(`api/finished-jobs-notification-queries/${id}`).then(response => {
            const finishedJobsNotificationQuery = response.data;
            dispatch(success(finishedJobsNotificationQueryConstants.GET_SUCCESS, {one: finishedJobsNotificationQuery}));
            return finishedJobsNotificationQuery; // Ensure the promise resolves with the query data
        }).catch(error => {
            if (error.response) {
                dispatch(failure(finishedJobsNotificationQueryConstants.GET_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
            throw error; // Ensure the promise rejects with the error
        });
    }
}

function update(id, notification_description, query, severity, user_notes) {
    return dispatch => {
        dispatch(request(finishedJobsNotificationQueryConstants.UPDATE_REQUEST));

        api().put(`api/finished-jobs-notification-queries/${id}`, {id, notification_description, query, severity, user_notes}).then(response => {
            dispatch(success(finishedJobsNotificationQueryConstants.UPDATE_SUCCESS));
            dispatch(alertActions.success('Finished Jobs Notification Query updated successfully'));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(finishedJobsNotificationQueryConstants.UPDATE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

function remove(id) {
    return dispatch => {
        dispatch(request(finishedJobsNotificationQueryConstants.DELETE_REQUEST));

        return api().delete(`api/finished-jobs-notification-queries/${id}`).then(response => {
            dispatch(success(finishedJobsNotificationQueryConstants.DELETE_SUCCESS));
            dispatch(alertActions.success('Finished Jobs Notification Query deleted successfully'));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(finishedJobsNotificationQueryConstants.DELETE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}

function create(notification_description, query, severity, user_notes) {
    return dispatch => {
        dispatch(request(finishedJobsNotificationQueryConstants.CREATE_REQUEST));

        api().post('api/finished-jobs-notification-queries', {notification_description, query, severity, user_notes}).then(response => {
            dispatch(success(finishedJobsNotificationQueryConstants.CREATE_SUCCESS));
            dispatch(alertActions.success('Finished Jobs Notification Query created successfully'));
        }).catch(error => {
            if (error.response) {
                dispatch(failure(finishedJobsNotificationQueryConstants.CREATE_FAILURE, {error: error.response.data.message, status: error.response.status}));
                dispatch(alertActions.error(error.response.data.message));
                dispatch(commonActions.failure(error.response.data.message));
            }
        });
    };
}
