import React from 'react';
import FinishedJobsNotificationQueriesGrid from './FinishedJobsNotificationQueriesGrid';
import {CCard, CCardHeader} from "@coreui/react";
import {history} from "../../_helpers";

const FinishedJobsNotificationQueries = () => {

    return (
        <CCard className="finished-jobs-grid order-grid">
            <CCardHeader>
                <strong>Notification Queries</strong>
                {/* Link to redirect to /finished-jobs-notification-queries/new where we can add a new notification record */}
                <button className="btn btn-primary float-right"
                        onClick={() => history.push('/finished-jobs-notification-queries/new')}>Add New
                </button>

            </CCardHeader>
            <div>
                <div>
                    <FinishedJobsNotificationQueriesGrid/>
                </div>
            </div>
        </CCard>
    )
};

export default FinishedJobsNotificationQueries;