import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { finishedJobsNotificationQueriesActions } from 'src/store/_actions';
import { useParams, useHistory } from 'react-router-dom';
import { CCard, CCardHeader } from "@coreui/react";

const FinishedJobsNotificationQuery = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const [notification_description, setNotificationDescription] = useState('');
    const [query, setQuery] = useState('');
    const [severity, setSeverity] = useState('');
    const [user_notes, setUserNotes] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(finishedJobsNotificationQueriesActions.one(id)).then(response => {
                if (response) {
                    const { notification_description, query, severity, user_notes } = response;
                    setNotificationDescription(notification_description || '');
                    setQuery(query || '');
                    setSeverity(severity || '');
                    setUserNotes(user_notes || '');
                }
            }).catch(error => {
                console.error('Error fetching notification query:', error);
            });
        }
    }, [id, dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (id && id !== 'new') {
            dispatch(finishedJobsNotificationQueriesActions.update(id, notification_description, query, severity, user_notes));
        } else {
            dispatch(finishedJobsNotificationQueriesActions.create(notification_description, query, severity, user_notes));
        }
    }

    const onCancel = () => {
        history.push('/finished-jobs-notification-queries');
    }

    return (
        <CCard className="finished-jobs-grid order-grid">
            <CCardHeader>
                <strong>Finished Jobs Notification Query</strong>
            </CCardHeader>
            <div>
                <div className={"mx-3 my-3"}>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="notification_description">Notification Description</label>
                            <input type="text" className="form-control" id="notification_description"
                                   value={notification_description}
                                   onChange={e => setNotificationDescription(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="query">Query</label>
                            <textarea
                                className="form-control"
                                id="query"
                                rows="10"
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="severity">Severity</label>
                            <select className="form-control" id="severity" value={severity}
                                    onChange={e => setSeverity(e.target.value)}>
                                <option value="lowest">lowest</option>
                                <option value="low">low</option>
                                <option value="medium">medium</option>
                                <option value="high">high</option>
                                <option value="highest">highest</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="user_notes">User Notes</label>
                            <input type="text" className="form-control" id="user_notes" value={user_notes}
                                   onChange={e => setUserNotes(e.target.value)}/>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                        <button type="button" className="btn btn-secondary ml-2" onClick={onCancel}>Cancel</button>
                    </form>
                </div>
            </div>
        </CCard>
    );
}

export default FinishedJobsNotificationQuery;