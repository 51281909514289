export const finishedJobsNotificationConstants = {
    GET_FAILURE: 'GET_FAILURE',
    UPDATE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_FAILURE',
    GET_REQUEST: 'GET_REQUEST',
    GET_SUCCESS: 'GET_SUCCESS',
    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',
    CREATE_REQUEST: 'CREATE_REQUEST',
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    CREATE_FAILURE: 'CREATE_FAILURE',
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',
}